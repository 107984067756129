// src/pages/preview.js
import * as React from "react"
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'

const PreviewPage = () => {
  return <p>Loading</p>
}

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: 'linkfive',
    linkResolver: require("../../utils/link-resolver").linkResolver,
  },
])